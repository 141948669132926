<template>
  <div class="commentOrder" :class="list.length==0?'white':''">
    <div class="header"><img src="../assets/chat_btn_return.png" alt="" @click="returnFun">选择评价订单</div>
    <div class="main" v-if="list.length>0">
      <div class="item" v-for="(item,index) in list" @click="chose(index)">
        <div>{{item.OrderTitle}}</div>
        <div>订单状态：{{item.StateName}}</div>
        <div>下单时间：{{item.PayDate}}</div>
        <img :src="order.OrderGuid==item.OrderGuid?require('../assets/icon_evaluate_selected.png'):require('../assets/icon_evaluate_normal.png')" alt="">
      </div>
    </div>
    <div class="foot" v-if="list.length>0">
      <div class="btn" @click="goComment">下一步</div>
    </div>
    <div class="noList" v-else>
      <img src="../assets/img_no_evaluate.png" alt="">
      <div>暂无可评价订单</div>
    </div>
  </div>
</template>
<script>
  
  export default {
    name: 'commentOrder',
    data() {
      return {
        list:[],
        order:{}
      }
    },
    mounted() {
      this.list=JSON.parse(localStorage.getItem('orderList'))
    },
    methods: {
      chose(index){
        this.order=this.list[index]
      },
      returnFun(){
        this.$router.back()
      },
      goComment(){
        if(JSON.stringify(this.order)!='{}'){
          this.$router.replace('/comment?orderguid='+this.order.OrderGuid+'&lawyerguid='+this.order.LawyerGuid)
        }else{
          this.$toast('请选择评价订单')
        }
      }
    },
    components: {
     
    }
  }

</script>

<style scoped lang="scss">
.commentOrder{
  width: 100%;height: 100%;
  position: absolute;
  top: 0;
  left: 0;background: #F5F5F8;
  .header{
    background: #fff;
    text-align: center;height: 80px;line-height: 80px;font-size: 30px;font-weight: bold;
color: #000000;position: relative;
    img{
      width: 17px;
      position: absolute;
      top: 25px;
      left: 30px;
    }
  }
  .main{
    height: calc(100% - 200px);
    overflow: auto;
    box-sizing: border-box;
    padding: 40px 30px 0;
    .item{
      padding: 30px;position: relative;background: #FFFFFF;
border-radius: 20px;margin-bottom: 30px;
      >div:nth-child(1){
        font-size: 32px;font-weight: bold;color: #333333;margin-bottom: 10px;
      }
      >div:nth-child(2){
        margin-bottom: 5px;
      }
      >div:nth-child(2),
      >div:nth-child(3){
        font-size: 24px;color: #666666;
      }
      img{
        width: 40px;
        position: absolute;
        top: 50%;
        margin-top: -20px;
        right: 30px;
      }
    }
  }
  .foot{
    height: 120px;
    background: #FFFFFF;
    box-sizing: border-box;
    padding: 15px 30px;
    .btn{
      width: 690px;
      height: 90px;
      background: #21D0C8;
      border-radius: 45px;font-size: 32px;color: #FFFFFF;
      text-align: center;line-height: 90px;
    }
  }
  .noList{
    img{
      width: 228px;margin-bottom: 25px;
    }
    font-size: 24px;color: #666666;text-align: center;
    position: absolute;
    top: 50%;
    margin-top: -135px;
    left: 50%;
    margin-left: -114px;
  }
}
.white{
  background: #fff;
}

</style>
<style>


</style>
